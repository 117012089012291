import { Check, ChevronsUpDown } from 'lucide-react';
import * as React from 'react';
import { Button } from './button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from './command';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { cn } from './utils';

export type ComboboxProps = {
  options: { value: string; label: string }[];
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  triggerClassName?: string;
  contentClassName?: string;
  commandClassName?: string;
  commandItemClassName?: string;
  hideIcon?: boolean;
  filter?: (value: string, search: string) => number;
  disabled?: boolean;
};

export function Combobox({
  options,
  placeholder,
  value,
  setValue,
  triggerClassName,
  contentClassName,
  commandClassName,
  commandItemClassName,
  filter,
  hideIcon,
  disabled,
}: ComboboxProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn('w-[200px] justify-between', triggerClassName)}
          disabled={disabled}
        >
          {value
            ? options.find((option) => option.value.toLowerCase() === value)
                ?.label
            : placeholder}
          {!hideIcon && (
            <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          'max-h-96 w-[200px] overflow-scroll p-0',
          contentClassName,
        )}
        align="start"
      >
        <Command className={commandClassName} filter={filter}>
          <CommandInput placeholder={placeholder} />
          <CommandEmpty>Nothing found.</CommandEmpty>
          <CommandGroup>
            {options.map((option) => (
              <CommandItem
                key={option.value}
                value={option.value}
                onSelect={(currentValue) => {
                  setValue(currentValue === value ? '' : currentValue);
                  setOpen(false);
                }}
                className={commandItemClassName}
              >
                <Check
                  className={cn(
                    'mr-2 h-4 w-4',
                    value === option.value ? 'opacity-100' : 'opacity-0',
                  )}
                />
                {option.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
